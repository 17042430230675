<template>
  <div class="contactUs">
    <div class="contactUs_title">
      Countact Us
    </div>
    <div class="say">
      <h1>Partner with us</h1>
    </div>
    <div class="contact">
      <p>
        Paid sponsorships with brands <br />PR collaborations <br />Boosting
        your income<br />
        Expanding your profile and growing your audience
      </p>
      <p>Please contact hello@kolboxsocial.com</p>
      <p>Text: 213-259-3638</p>
    </div>
    <div class="contactUs_title">
      About You
    </div>
    <div class="input_box">
      <div class="name">
        <div class="tag">FULL NAME*</div>
        <input type="text" v-model="state.fullName" />
      </div>
      <div class="email">
        <div class="tag">EMAIL*</div>
        <input type="text" v-model="state.email" />
      </div>
    </div>
    <div class="text_title">MESSAGE:</div>
    <textarea
      name=""
      id=""
      cols="30"
      rows="10"
      v-model="state.message"
    ></textarea>
    <div class="submit" @click="submit">SUBMIT</div>
    <div
      class="tishi"
      v-show="state.showTishi"
      :class="{ success: state.success }"
    >
      {{ state.tishi }}
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import axios from "axios";
export default {
  setup() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const state = reactive({
      fullName: "",
      email: "",
      message: "",
      tishi: "",
      showTishi: false,
      success: false
    });
    const isEmailAvailable = emailInput => {
      var myreg = /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+.[a-zA-Z]{2,3}$/;
      if (!myreg.test(emailInput)) {
        return false;
      } else {
        return true;
      }
    };
    const submit = async () => {
      const { fullName, email, message } = state;
      let count = 0;
      state.success = false;
      if (!isEmailAvailable(email)) {
        state.tishi = "E-mail format is incorrect";
        count++;
      }
      if (message.trim().length < 1) {
        state.tishi = "Message cannot be empty";
        count++;
      }
      if (email.trim().length < 1) {
        state.tishi = "Email cannot be empty";
        count++;
      }
      if (fullName.trim().length < 1) {
        state.tishi = "Full name cannot be empty";
        count++;
      }
      if (count > 0) {
        state.showTishi = true;
        setTimeout(() => {
          state.showTishi = false;
        }, 2000);
        return;
      }
      const { data } = await axios.post(
        "https://p.kolbox.com/kol-api/storecustomermessage/storeCustomerMessage/add",
        {
          fullName,
          email,
          message
        }
      );
      console.log(data);
      if (data.success) {
        state.tishi = "Submitted successfully";
        state.success = true;
        state.fullName = "";
        state.email = "";
        state.message = "";
      } else {
        state.tishi = "Submission Failed";
      }
      state.showTishi = true;
      setTimeout(() => {
        state.showTishi = false;
      }, 2000);
    };
    return { state, submit };
  }
};
</script>
<style lang="less" scoped>
.contactUs {
  .contactUs_title {
    text-align: center;
    margin: 0.45rem 0;
    font-size: 0.58rem;
    font-family: Arial;
    font-weight: normal;
    color: #000000;
  }
  .say {
    h1 {
      font-size: 0.6rem;
      font-family: Arial;
      font-weight: 900;
      color: #f7323f;
    }
    h1:nth-child(1) {
      margin-left: 0.76rem;
    }
  }
  .contact {
    padding: 0.7rem 0.7rem 0;
    p {
      font-size: 0.24rem;
      font-family: Arial;
      font-weight: 400;
      color: #000000;
      line-height: 0.35rem;
    }
    p:nth-child(1) {
      margin-bottom: 0.4rem;
    }
    p:nth-child(n + 2) {
      font-size: 0.3rem;
      line-height: 0.45rem;
    }
  }
  .input_box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .name,
    .email {
      width: 6.85rem;
      height: 1rem;
      background: #fafcf8;
      border: 0.02rem solid #adadad;
      .tag {
        height: 0.25rem;
        font-size: 0.21rem;
        font-family: Arial;
        font-weight: 400;
        color: #666666;
        margin: 0.25rem 0 0 0.14rem;
      }
      input {
        box-sizing: border-box;
        background: #fafcf8;
        width: 100%;
        height: 0.5rem;
        padding: 0 0.3rem;
        font-size: 0.2rem;
      }
    }
    .email {
      border-top: none;
    }
  }
  .text_title {
    width: 1rem;
    height: 0.25rem;
    font-size: 0.21rem;
    font-family: Arial;
    font-weight: 400;
    color: #666666;
    margin: 0.25rem 0 0 0.14rem;
    position: relative;
    top: 0.5rem;
    left: 0.32rem;
  }
  textarea {
    font-family: Arial;
    font-size: 0.2rem;
    display: block;
    box-sizing: border-box;
    width: 6.85rem;
    height: 2.56rem;
    background: #fafcf8;
    border: 0.02rem solid #adadad;
    margin: 0 auto;
    padding: 0.6rem 0.3rem 0;
  }
  .submit {
    width: 6.85rem;
    height: 0.73rem;
    line-height: 0.73rem;
    background: #f7323f;
    text-align: center;
    margin: 1rem auto 0.6rem;
    font-size: 0.24rem;
    font-family: Arial;
    font-weight: bold;
    color: #ffffff;
  }
  .tishi {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.8;
    width: 4rem;
    height: 0.7rem;
    background: rgb(233, 105, 105);
    color: #fff;
    border-radius: 0.1rem;
    text-align: center;
    line-height: 0.7rem;
  }
  .success {
    background: #6bd861;
  }
}
</style>
